.timerPanel{
    background-color: white;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 20px;
}
.progress{
    height: 0.6rem !important;
    width: 100%;
    background: #eeeeee;
    border-radius: 10px;
    margin-left: 10px; 
    margin-right: 10px;
}
.progress-bar{
    border-radius: 10px;
    background-color: #00b9c2 !important;
    height: 100% !important;
}