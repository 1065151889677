input[type=number].noSpin::-webkit-inner-spin-button,
input[type=number].noSpin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table {
  width: 100%;
}

table,
th,
td {
  border: 0px solid;
  padding: 5px;
}