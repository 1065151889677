body {
  background-color: #f39631;
}
.navbar {
  position: fixed;
  width: 100vw;
}
.card.examps .card-text button {
  width: 80%;
  padding: 15px;
  font-size: 14px;
}

.card.examps .card-text button:hover {
  /* background-color: rgba(247, 240, 240, 0.89); */
}
