.CircularProgressbar {
    height: 25px;
}
.CircularProgressbar .CircularProgressbar-path{
    stroke: #00b9c2 !important;
}

.TimerProgress{
    width: 100px;
    display: flex;
    align-items: center;
    
}

.TimerProgress span:nth-child(1){
    flex:1;
    font-weight: bold;
    color: #00b9c2 !important;
}

.TimerProgress svg:nth-child(2){
    flex:1
}
